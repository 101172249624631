export const PrimaryMenu = [
  {
    label: "Categories",
    path: "/categories"
  },
  {
    label: "Sponsors",
    path: "/#sponsors",
  },
  {
    label: "Judges",
    path: "/judges",
  },
  // {
  //   label: "Topics",
  //   path: "/#topics",
  // },
  // {
  //   label: "Attendees",
  //   path: "/#attendees",
  // },
  // {
  //   label: "Sponsorship",
  //   path: "/#sponsorship",
  // },
  {
    label: "Venue",
    path: "/#venue",
  },
  {
    label: "FAQ",
    path: "/faq",
  },
  // {
  //   label: "Finalists",
  //   path: "/finalists",
  // },
  {
    label: "Gallery",
    path: "/gallery",
  },
];

export const SecondaryMenu = [
  
  {
    label: "Entry Tips",
    path: `/entry-tips`,
  },
];

export const PrimaryFooterMenu = [
  {
    id: "entry_tips",
    label: "Entry Tips",
    path: `/entry-tips`,
  },
  {
    id: "faq",
    label: "FAQ",
    path: `/faq`,
  },
  {
    id: "gallery",
    label: "Gallery",
    path: `/gallery`,
  },
];

export const SecondaryFooterMenu = [
  {
    id: "contact_us",
    label: "Contact us",
    path: `/#contacts`,
  },
  {
    id: "privacy_policy",
    label: "Privacy Policy",
    path: `https://www.bpl-business.com/privacy-policy`,
  },
  {
    id: "terms_conditions",
    label: "Terms & Conditions",
    path: `https://www.bpl-business.com/privacy-policy`,
  },
  {
    id: "cookie_policy",
    label: "Cookie Policy",
    path: `https://www.bpl-business.com/privacy-policy`,
  },
];

export const EventFooterMenu = [
  {
    id: "comms_dealer",
    label: "Comms-dealer.com",
    path: `https://www.comms-dealer.com/`,
  },
  {
    id: "channel_champions",
    label: "Channel Champions Awards",
    path: `https://www.channel-champions.com/`,
  },
  {
    id: "comms_vision",
    label: "Comms Vision Convention",
    path: `https://www.commsvision.com/`,
  },
  {
    id: "fibre_provider",
    label: "Fibre Provider",
    path: `https://fibreprovider.net/`,
  },
  {
    id: "partner_week",
    label: "UK Partner Week",
    path: `https://partnerweek.uk/`,
  },
];
