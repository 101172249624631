import React from "react";
import Message from '../../components/Message/Message'
import PageTitle from '../../components/PageTitle/PageTitle'
import styles from "./Entry-tips.module.scss"
import { ENTRY_DEADLINE, EVENT_NAME, EVENT_YEAR } from "../../Constants/constants";


const EntryTips = () => {
  return (
    <>
      <PageTitle>Entry Tips</PageTitle>
      <div className={`container-75ch ${styles.entry__wrapper}`}>
        <p className={styles.entry__intro}>
          How to put together a top notch entry for {EVENT_YEAR} {EVENT_NAME}.
          {' '}The {EVENT_NAME} is the most highly regarded awards process in
          the ICT channel and is fairly and independently judged by a highly
          experienced panel of channel experts.
        </p>
        <h2 className={styles.entry__title}>Overview</h2>
        <h3 className={styles.entry__subtitle}>Vendor Awards</h3>
        <p>
          The Vendor Awards are for Carriers/Network Providers/ Distributors &
          Service Providers and recognise the products, support and service they
          provide to third party reseller partners.
        </p>
        <h3 className={styles.entry__subtitle}>Reseller Awards</h3>
        <p>
          The Reseller Awards are for third party solution providers (systems
          integrators, resellers, dealers) and recognise the effectiveness,
          quality and ROI of solutions provided to end user customers.
        </p>
        <h3 className={styles.entry__subtitle}>Business Awards</h3>
        <p>
          The Business Awards are for Carriers, Network Providers, Distributors,
          Service Providers, Systems Integrators, Resellers, and Dealers and
          recognise growth, success and innovation.
        </p>
        <h2 className={styles.entry__title}>The benefits of entering&hellip;</h2>
        <ul className={styles.entry__lists}>
          <li>Raise your profile across the UK ICT channel</li>
          <li>Benchmark yourself against other UK channel companies</li>
          <li>Acknowledge your team's efforts</li>
          <li>Influence and impress partners, clients and investors</li>
          <li>Improve staff morale</li>
          <li>
            A finalist position will provide a platform for follow on PR and
            marketing activities in the trade, local and regional press…
          </li>
          <li>
            You will receive a finalist or winners' logo you can use as an email
            signature and in all your marketing collateral. As this is a national
            awards programme{" "}
            <em>do not not under-estimate the influence this logo carries!</em>
          </li>
          <li>
            And as a finalist you will of course get the opportunity to treat your
            clients and staff to the best night out in the comms industry!
          </li>
        </ul>
        <h2 className={styles.entry__title}>The entry process</h2>
        <ul className={styles.entry__lists}>
          <li>Entry is FREE!</li>
          <li>Register here</li>
          <li>Download the relevant vendor, reseller or business entry form.</li>
          <li>Choose the award category or categories you wish to enter.</li>
          <li>Answer the questions on the form.</li>
          <li>
            Send your completed form to{" "}
            <a href='mailto:awards@bpl-business.com?subject=Completed%20entry%20form'>
              awards@bpl-business.com
            </a>{" "}
            with testimonials, and relevant supporting information (preferably in
            pdf format) by Wednesday {ENTRY_DEADLINE}.
          </li>
        </ul>
        <h2 className={styles.entry__title}>Tips for your main entry</h2>
        <ul className={styles.entry__lists}>
          <li className={styles.entry__bullets}>
            NB: The {EVENT_NAME} acknowledge solutions, services and
            products delivered between July 31st 2024 and July 16th 2025.
          </li>
          <li className={styles.entry__bullets}>
            Answer the questions succinctly, do not flannel or write flowery or
            highly technical text. Stick to the facts! You can enter for as many
            awards as you like, but try and avoid cutting and pasting the same
            answers into different category entries. Each entry should be relevant
            to the category you have selected.
          </li>
          <li className={styles.entry__bullets}>
            Remember the judges are reading through scores of entries so when
            answering each question try to write two or three paragraphs of
            summary followed by bullet points that point out the key aspects and
            benefits of the product, service or solution. The judges give marks
            for each section so read through the entire form before starting to
            answer the questions. Writing 'see above' could count against you.
          </li>
          <li>
            Stick to the word count, as you may get marked down if you don't!
          </li>
          <li>
            Do not forget to check your entry for spelling and grammar. A poorly
            worded entry can be the difference between getting shortlisted or not.
          </li>
        </ul>

        <div>
          <Message type={'info'}>
            <p><strong>Important notice:</strong></p>
            <p>

              Please note that enhanced/designed entries will <em>no longer be accepted.</em>
              {" "}<strong>All entrants must use the entry form only. </strong>Supporting
              information presented in PDF format will be accepted, along with
              relevant video links.

            </p>
          </Message>
        </div>

        <h2 className={styles.entry__title}>Tips for your testimonials</h2>
        <ul className={styles.entry__lists}>
          <li className={styles.entry__bullets}>
            Do not just copy and attach an email from a client or partner - a
            legitimate signed letter on header paper saved as a pdf and attached
            to your entry is what the judges are keen to see.
          </li>
          <li className={styles.entry__bullets}>
            You can send in multiple testimonials but 3 or 4 good ones are better
            than scores of them saying how wonderful you are!
          </li>
          <li className={styles.entry__bullets}>
            Do not send in testimonials from another awards programme; it's lazy
            and the judges hate it.
          </li>
        </ul>
        <h2 className={styles.entry__title}>Tips for your supporting information</h2>
        <ul className={styles.entry__lists}>
          <li className={styles.entry__bullets}>
            The judges welcome succinct supporting information relevant to the
            entry e.g. Product sheets, infrastructure diagrams, channel support
            collateral etc. but don't over do it. Relevance is key.
          </li>
          <li className={styles.entry__bullets}>
            The judges will also welcome links to videos e.g. testimonials, case
            studies relevant to your entries but they must not be over long!
          </li>
        </ul>
        <h2 className={styles.entry__title}>Notes for entrants</h2>
        <h3 className={styles.entry__subtitle}>Judges notes for Reseller entrants</h3>
        <p>
          Read the question. It is staggering how many entrants fail to answer the
          question properly and ramble on about irrelevancies!
        </p>
        <p>
          This year we will be looking for reseller entries where they have shown
          that the solution has improved the 'customer experience'. In today's
          customer led world the experience is everything. Having a clever
          solution that saves a company money is no longer just good enough.
        </p>
        <h3 className={styles.entry__subtitle}>Judges notes for Vendor entrants</h3>
        <p>
          We will be looking for entries that show the supplier has gone beyond
          just having a great product or service.
        </p>
        <p>
          Their go to market strategy and implementation must show they understand
          the channel's issues about running a successful business and have
          created marketing, sales and services programs to support their
          partners.
        </p>
        <h3 className={styles.entry__subtitle}>Judges notes for Business entrants</h3>
        <p>
          We will be looking for entries that have shown how a channel business
          operating in the vendor, distributor, service provider or reseller arena
          has achieved success and growth via customer service and/or innovation.
        </p>
        <h2 className={styles.entry__title}>To complete your entry...</h2>
        <ul className={styles.entry__lists}>
          <li>
            Please make sure you advise us if any information provided in your
            entry is confidential.
          </li>
          <li>You must provide your company logo in JPG or EPS format.</li>
        </ul>
        <h2 className={styles.entry__title}>And finally...</h2>
        <p>Remember the deadline for entries is {ENTRY_DEADLINE}</p>
        <p>
          If you need further assistance, please contact us by email{" "}
          <a href='mailto:awards@bpl-business.com?subject=Assistance%20for%20Comms%20National%20Awards'>
            awards@bpl-business.com
          </a>{" "}
          or call 07759 731134.
        </p>
      </div>
    </>
  );
};

export default EntryTips;
