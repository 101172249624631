export const sponsors = [

  {
      order: 1,
      type: 'Headline Sponsor',
      sponsors: [
        {
          name: 'Giacom',
          image: 'giacom.webp',
          link: 'https://www.giacom.com/'
        },
      ]
    },
  {
    order: 2,
    type: 'Category Sponsors',
    sponsors: [
      {
          name: 'Jola',
          image: 'jola.webp',
          link: 'https://www.jola.co.uk/'
      },
    ]
  },
  // {
  //   order: 2,
  //   type: '2024 Headline Sponsor',
  //   sponsors: [
  //     {
  //       name: 'Giacom',
  //       image: 'giacom.webp',
  //       link: 'https://www.giacom.com/'
  //     },
  //   ]
  // },
  // {
  //   order: 3,
  //   type: '2024 Category Sponsors',
  //   sponsors: [
  //     {
  //       name: 'ANS',
  //       image: 'ans.webp',
  //       link: 'https://www.ans.co.uk/'
  //     },
  //     {
  //       name: 'Gamma',
  //       image: 'gamma.webp',
  //       link: 'https://gammagroup.co/'
  //     },
  //     {
  //       name: 'IP Netix',
  //       image: 'ipnetix.webp',
  //       link: 'https://ip-netix.com/'
  //     },
  //     {
  //       name: 'Jola',
  //       image: 'jola.webp',
  //       link: 'https://www.jola.co.uk/'
  //     },
  //     {
  //       name: 'Knight Corporate Finance',
  //       image: 'knight.webp',
  //       link: 'https://knightcf.com/'
  //     },
  //     {
  //       name: 'PXC',
  //       image: 'pxc.webp',
  //       link: 'https://pxc.co.uk/'
  //     },
  //     {
  //       name: 'Sky',
  //       image: 'sky-business.webp',
  //       link: 'https://business.sky.com/'
  //     },
  //     {
  //       name: 'TelXL',
  //       image: 'telxl.webp',
  //       link: 'https://telxl.com/'
  //     },
  //     {
  //       name: 'TSI',
  //       image: 'tsi.webp',
  //       link: 'https://www.tsicomms.co.uk/'
  //     },
  //     {
  //       name: 'Voiceflex',
  //       image: 'voiceflex.webp',
  //       link: 'https://www.voiceflex.com/'
  //     },
  //     {
  //       name: 'Xelion',
  //       image: 'xelion.webp',
  //       link: 'https://xelion.com/'
  //     },
  //   ]
  // },
  // {
  //   order: 4,
  //   type: '2024 Hospitality Sponsor',
  //   sponsors: [
  //     {
  //       name: 'Inform Billing',
  //       image: 'inform-billing.webp',
  //       link: 'https://www.informbilling.co.uk/'
  //     },
  //     {
  //       name: 'Sky',
  //       image: 'sky-business.webp',
  //       link: 'https://business.sky.com/'
  //     },
  //   ]
  // },
  // {
  //   order: 5,
  //   type: 'After Party Sponsor',
  //   sponsors: [

  //     {
  //       name: 'BT Wholesale',
  //       image: 'bt-wholesale.png',
  //       link: 'https://www.btwholesale.com/'

  //     }

  //   ]
  // }
]


export const sponsors23 = [
  {
    order: 1,
    type: 'Diamond',
    sponsors: [
      {
        name: 'ConnectWise',
        image: 'connectwise.webp',
        link: 'https://www.connectwise.com/'
      },
      {
        name: 'Datto',
        image: 'datto.webp',
        link: 'https://www.itcomplete.com/'
      },
      {
        name: 'Opentext',
        image: 'opentext.webp',
        link: 'https://www.opentext.com/'
      },
      {
        name: 'Pax8',
        image: 'pax8.webp',
        link: 'https://www.pax8.com/en-uk/'
      },
      {
        name: 'Pia',
        image: 'pia.webp',
        link: 'https://pia.ai/'
      },
      {
        name: 'Threatlocker',
        image: 'threatlocker.webp',
        link: 'https://www.threatlocker.com/'
      }
    ]
  },
  {
    order: 2,
    type: 'Platinum',
    sponsors: [
      {
        name: 'Giacom',
        image: 'giacom.webp',
        link: 'https://www.cloudmarket.com/'
      },
      {
        name: 'Sophos',
        image: 'sophos.webp',
        link: 'https://www.sophos.com/'
      }
    ]
  },
  {
    order: 3,
    type: 'Gold',
    sponsors: [
      {
        name: 'Acronis',
        image: 'acronis.webp',
        link: 'https://www.acronis.com/en-gb/'
      },
      {
        name: 'Brigantia',
        image: 'brigantia.webp',
        link: 'https://www.brigantia.com/'
      },
      {
        name: 'eSentire',
        image: 'esentire.webp',
        link: 'https://www.esentire.com/'
      },
      {
        name: 'Heimdel',
        image: 'heimdel.webp',
        link: 'https://heimdalsecurity.com/'
      },
      {
        name: 'LogPoint',
        image: 'logpoint.webp',
        link: 'https://www.logpoint.com/en/'
      }
    ]
  },
  {
    order: 4,
    type: 'Silver',
    sponsors: [
      {
        name: 'Cynomi',
        image: 'cynomi.webp',
        link: 'https://www.cynomi.com/'
      },
      {
        name: 'Liverton Security',
        image: 'liverton-security.webp',
        link: 'https://www.livertonsecurity.com/'
      },
      {
        name: 'LogRhythm',
        image: 'logrhythm.webp',
        link: 'https://logrhythm.com/'
      },
      {
        name: 'Ninja',
        image: 'ninja.webp',
        link: 'https://www.ninjaone.com/home-b/'
      },
      {
        name: 'WatchGuard',
        image: 'watchguard.webp',
        link: 'https://watchguard.com/'
      }
    ]
  },
  {
    order: 5,
    type: 'Lanyard',
    sponsors: [
      {
        name: 'ConnectWise',
        image: 'connectwise.webp',
        link: 'https://www.connectwise.com/'
      }
    ]
  },
  {
    order: 6,
    type: 'Sustainability Partner',
    sponsors: [
      {
        name: 'Relltek',
        image: 'relltek.webp',
        link: 'https://www.relltek.com/'
      }
    ]
  }
]