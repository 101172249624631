export const items = [
  {
    id: "entering",
    name: "Entering the awards",
    questions: [
      {
        title: "What advice can we give to entrants?",
        content:
          "It's time to register, but you're wondering what advice we can give? Well, you're in the right place. Below you’ll see some of the most frequent questions we get asked, from the process of starting your entry, to actually hitting that register button.",
      },
      {
        title: "When is the entry deadline?",
        content:
          "All entries and supporting documentation must be received no later than 5pm Wednesday 10th July 2025.",
      },
      {
        title: "How many categories can I enter?",
        content:
          "You can enter as many categories as you like. For obvious reasons, we ask you to follow the format of the registration and register only for the categories that are directly relevant to you.",
      },
      {
        title: "How do I submit an entry?",
        content:
          "To submit an entry you must first register for the event. Once you have registered, you will be redirected to a page where you can download the entry forms (in MS Word format). You will then need to fill in the forms following the instructions within the document. Once you have filled out the form, you will need to send a copy of your completed form as an MS Word or pdf document to awards@bpl-business.com",
      },
      {
        title: "What makes an entry good?",
        content:
          "We have some tips on how to create a stronger entry, you can see what advice we have on our entry tips page.",
      },
    ],
  },
  {
    id: "announcements",
    name: "Event announcements and assets",
    questions: [
      {
        title: "When will the finalists be announced?",
        content:
          "Finalists will be announced within a month of the final entry deadline.",
      },
      {
        title: "Where can I download my finalist / winners logo?",
        content:
          "When the finalists/winners have been announced, we will advise you of a link from where you will be able to download your logos.",
      },
      {
        title: "Do we receive 'Highly commended' certificates?",
        content:
          "Yes - We will provide certificates to entrants who are highly commended on the finals night.",
      },
    ]
  },
  {
    id: "issues",
    name: "Issues and Assistance",
    questions: [
      {
        title: "Have trouble opening the form?",
        content:
          "When you are attempting to download the entry forms we recommend that you save them to your PC (rather than pressing open) and then making any edits.",
      },
      
    ]
  }  
];
