import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom"; // Import useParams to access uuid from route

// Import your data file to get the category names
import { data as categoryData } from "../Category/CategoryData";

import styles from './EntryContact.module.scss';
import Message from "../Message/Message";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import PageTitle from "../PageTitle/PageTitle";
import ButtonStyles from "../Button/Button.module.scss";
import Contacts from "../Contact/Contacts";
import { ENTRY_DEADLINE, SITE_URL } from "../../Constants/constants";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const EntryContact = () => {
  const { uuid } = useParams();  // Retrieve uuid from the route
  const [details, setDetails] = useState({});
  const [categoryLinks, setCategoryLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([])
  const [allCategories, setAllCategories] = useState([]);

  // Function to flatten categoryData
  const flattenCategories = (data) => {
    const result = data.flatMap(topic => topic.categories);
    setCategories(result);
    return result
  };

  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true);
      setError(""); // Clear any previous error
      try {
        const response = await axios.get(`${SITE_URL}/backend/getCategories.php?uuid=${uuid}`, {
          withCredentials: true,
        });
  
        if (response.data.error) {
          // Handle backend error
          throw new Error(response.data.error);
        }
  
        // Process data from backend
        const { first_name, business_categories, reseller_categories, vendor_categories } = response.data;
  
        setDetails({ first_name });
  
        // Combine all categories entered by the user into an array
        const enteredCategories = [
          ...(Array.isArray(business_categories) ? business_categories : business_categories.split(", ").filter(Boolean)),
          ...(Array.isArray(reseller_categories) ? reseller_categories : reseller_categories.split(", ").filter(Boolean)),
          ...(Array.isArray(vendor_categories) ? vendor_categories : vendor_categories.split(", ").filter(Boolean)),
        ];
  
        // Flatten the categoryData for easier matching
        const flatCategoryData = flattenCategories(categoryData);
  
        // Match the entered categories with the available categoryData
        const matchedCategories = enteredCategories.map((categoryName) => {
          const category = flatCategoryData.find((c) => c.name === categoryName);
          return category ? { name: category.name, form: category.form } : null;
        }).filter(Boolean); // Remove null values if no match is found
  
        // Update the categoryLinks state to reflect the matched categories
        setCategoryLinks(matchedCategories);
      } catch (err) {
        console.error("Error fetching details:", err.message);
        setError(err.message || "There was an error fetching the details. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };
  
    if (uuid) {
      fetchDetails();
    }
  }, [uuid]);
  
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <ScrollToTop />
      <PageTitle>Registration forms</PageTitle>

      <div className="container-lg">
        <Breadcrumbs />
        <h2>{details.first_name ? (`${details.first_name}, thank you for entering!`) : 'Thank you for entering!'}</h2>
        <p className={styles['intro']}>Please see the entry forms below. Please fill out the form/forms for the category/categories which you are planning to enter. Reminder that the entry deadline is on {ENTRY_DEADLINE}.</p>

        {details.first_name && (
          <div>
            <h3>Download your entry forms</h3>
            <Message type={'info'}>
              <p><strong>Important Notice:</strong> Please note that enhanced/designed entries will no longer be accepted.</p>
            </Message>
            {(categoryLinks && categoryLinks.length > 0) ? (
              <ul className={styles['download-list']}>
                {categoryLinks.map((link, index) => (
                  <li key={index} className={styles['download-item']}>
                    <span className={styles['download-name']}>{link.name}</span>
                    <a className={`${ButtonStyles['button']} ${ButtonStyles['button_filled_primary']} ${ButtonStyles['btn_primary']}`} href={link.form} download>Download Form</a>
                  </li>
                ))}
              </ul>
            ) : (
              <ul className={styles['download-list']}>
                {categories.map((link, index) => (
                  <li key={index} className={styles['download-item']}>
                    <div className={styles['download-name']}>
                    <div className={styles['download-subcategory']}>{link.subcategory}</div>
                    <span>{link.name}</span></div>
                    <a className={`${ButtonStyles['button']} ${ButtonStyles['button_filled_primary']} ${ButtonStyles['btn_primary']}`} href={link.form} download>Download Form</a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        <div className={styles['next-steps-wrapper']}>
          <h3 className={styles['next-steps-heading']}>The next steps:</h3>
          <ol className={styles['next-steps-list']}>
            <li>
              <h4 className={styles['next-steps-title']}>Download your entry form(s)</h4>
              <div className={styles['next-steps-summary']}>We recommend that you <em>save them</em> to your PC (rather than pressing open) and then making any edits.</div>
            </li>
            <li>
              <h4 className={styles['next-steps-title']}>Complete the form(s)</h4>
              <div className={styles['next-steps-summary']}><strong>All entrants must use the entry form only.</strong> Supporting information presented in PDF format will be accepted, along with relevant video links.</div>
            </li>
            <li>
              <h4 className={styles['next-steps-title']}>Submit your entries</h4>
              <div className={styles['next-steps-summary']}>Once you have filled out the forms, please email them to Rachel/Jenny - <a href="mailto:awards@bpl-business.com" className={styles['next-steps-link']} rel="noopener">awards@bpl-business.com</a></div>
            </li>
          </ol>
        </div>

        <div className={styles['next-steps-wrapper']}>
          <h3 className={styles['next-steps-heading']}>Need some help or advice?</h3>
          <ul className={styles['next-steps-list']}>
            <li>
              <h4 className={styles['next-steps-title']}>Check the FAQ</h4>
              <div className={styles['next-steps-summary']}>For answers to our most common questions, take a look at our <a href="/faq" className={styles['next-steps-link']}>FAQ page</a></div>
            </li>
            <li>
              <h4 className={styles['next-steps-title']}>Advice for putting together a winning entry</h4>
              <div className={styles['next-steps-summary']}>The judges have put together a guide on how to put together a winning entry. See what the judges are looking for and how to make your entry shine on the <a href="/entry-tips" className={styles['next-steps-link']}>Entry Tips page</a>.</div>
            </li>
            <li>
              <h4 className={styles['next-steps-title']}>Something not working, or you need some help?</h4>
              <div className={styles['next-steps-summary']}>If something doesn't appear to be working or you need some help, contact Rachel/Jenny by email: <a href="mailto:awards@bpl-business.com" className={styles['next-steps-link']} rel="noopener">awards@bpl-business.com</a></div>
            </li>
          </ul>
        </div>

      </div>
      <Contacts />
    </>
  );
}

export default EntryContact;
